import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TypesService } from '@core/services/types.service';
import { Types } from '@core/models/types.model';
import { NotificationService } from '@core/services/notification.service';
import { HttpState } from '@core/types/http';
import { HttpStatus } from '@core/enums/http-status';
import { MAX_CACHE_AGE } from '@core/tokens/max-cache-age.token';

import { ComponentStore } from '@ngrx/component-store';
import { filterSuccess } from '@core/utils/http';
import { tapResponse } from '@ngrx/operators';
import { EMPTY, iif, of, switchMap, tap, withLatestFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TypesStore extends ComponentStore<HttpState<Types>> {
  public readonly types$ = this.state$;
  public readonly loadedTypes$ = this.types$.pipe(filterSuccess());

  constructor(
    @Inject(MAX_CACHE_AGE)
    private readonly maxCacheAge: number,
    private readonly typesService: TypesService,
    private readonly notification: NotificationService
  ) {
    super({ status: HttpStatus.Idle });
  }

  public readonly load = this.effect<void>((load$) =>
    load$.pipe(
      withLatestFrom(this.state$),
      switchMap(([_, { status, cachedAt }]) =>
        iif(
          () =>
            status !== HttpStatus.Success ||
            Date.now() - (cachedAt ?? 0) > this.maxCacheAge,
          of(void 0).pipe(
            tap(this.setLoading),
            switchMap(() => this.typesService.get()),
            tapResponse(this.setSuccess, this.setError)
          ),
          EMPTY
        )
      )
    )
  );

  private readonly setLoading = this.updater<void>((state) => ({
    ...state,
    status: HttpStatus.Loading,
  }));

  private readonly setSuccess = this.updater<Types>((_, types) => ({
    status: HttpStatus.Success,
    data: types,
    cachedAt: Date.now(),
  }));

  private readonly setError = this.updater<HttpErrorResponse>((_, error) => {
    this.notification.error(error.message);
    return {
      status: HttpStatus.Error,
      error,
      cachedAt: Date.now(),
    };
  });
}
