import { Inject, Injectable, InjectionToken, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Types } from '@core/models/types.model';

import { RUNTIME_CONFIGURATION } from '@core/tokens/runtime-configuration.token';

export const TYPES_ENDPOINT = new InjectionToken('TYPES_ENDPOINT', {
  providedIn: 'root',
  factory: () => `${inject(RUNTIME_CONFIGURATION).apiUrl}/types`,
});

@Injectable({ providedIn: 'root' })
export class TypesService {
  constructor(
    private readonly http: HttpClient,
    @Inject(TYPES_ENDPOINT) private readonly endpoint: string
  ) {}

  public get() {
    return this.http.get<Types>(this.endpoint);
  }
}
