import { filter, map, OperatorFunction } from 'rxjs';
import { HttpState, SuccessHttpDataState } from '@core/types/http';
import { HttpStatus } from '@core/enums/http-status';

export function isSuccess<T>(
  dataState: HttpState<T>
): dataState is SuccessHttpDataState<T> {
  return (
    dataState.status === HttpStatus.Success &&
    dataState.data !== undefined &&
    dataState.cachedAt !== undefined
  );
}

export function filterSuccess<T>(): OperatorFunction<
  HttpState<T>,
  SuccessHttpDataState<T>
> {
  return (source$) => source$.pipe(filter(isSuccess));
}
